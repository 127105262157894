<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ this.$route.params.name_en,}}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-dialog v-model="dialog" max-width="1000px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #5de99e"
                      >
                        <span style="font-size: 1em; color: #000000">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_sessions")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card color="teal lighten-3">
                      <v-card-title v-if="this.sessionId != ''">{{
                        $t("edit_sessions")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_sessions")
                      }}</v-card-title>

                      <v-divider />
                      <v-card-text
                        style="
                          height: 490px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col sm="6" cols="12" class>
                                <label class="label">{{ $t("name_en") }}</label>
                                <v-text-field
                                  class="disable_alert my-3"
                                  v-model="name_en"
                                  placeholder="e.g Term1"
                                  precision="3"
                                  outlined
                                  required
                                />
                                <label class="label">{{ $t("name_kh") }}</label>
                                <v-text-field
                                  class="disable_alert my-3"
                                  v-model="name_kh"
                                  placeholder="e.g ត្រីមាសទី១"
                                  precision="3"
                                  outlined
                                  required
                                />
                                <label class="label">{{
                                  $t("start_date")
                                }}</label>
                                <v-dialog
                                  ref="dialog1"
                                  v-model="menuJDate1"
                                  :return-value.sync="start_date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      class="disable_alert my-3"
                                      outlined
                                      placeholder="e.g 2021-01-01"
                                      v-model="start_date"
                                      append-icon="event"
                                      scrollable
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      required
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="start_date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuJDate1 = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog1.save(start_date)"
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-dialog>
                                <label class="label">{{
                                  $t("end_date")
                                }}</label>
                                <v-dialog
                                  ref="dialog2"
                                  v-model="menuJDate2"
                                  :return-value.sync="end_date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      class="disable_alert my-3"
                                      outlined
                                      placeholder="e.g 2021-01-01"
                                      v-model="end_date"
                                      append-icon="event"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      required
                                    />
                                  </template>
                                  <v-date-picker v-model="end_date" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuJDate2 = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog2.save(end_date)"
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-dialog>

                                <div style="margin-bottom: 8px"></div>
                                <label class="label required">{{
                                  $t("academic_id")
                                }}</label>
                                <div style="margin-bottom: 8px"></div>
                                <v-select
                                  v-model="academic_id"
                                  single-line
                                  :rules="[rules.required]"
                                  :items="listAcademicYear"
                                  item-text="name_en"
                                  item-value="id"
                                  label="All"
                                  outlined
                                  hide-details
                                >
                                </v-select>
                              </v-col>
                              <v-col sm="6" cols="12" class>
                                <v-card color="light-green lighten-5">
                                  <v-card-title>{{
                                    $t("assign_student")
                                  }}</v-card-title>

                                  <v-divider />
                                  <v-data-table
                                    :headers="headers1"
                                    :items="listStudent"
                                    :items-per-page="25"
                                    :footer-props="{
                                      'items-per-page-options': [
                                        10, 25, 50, -1,
                                      ],
                                    }"
                                    style="max-height: 400px; overflow-y: auto"
                                    item-key="id"
                                    hide-default-header
                                    class="elevation-1"
                                  >
                                    <template v-slot:header="{}">
                                      <thead>
                                        <tr>
                                          <th>
                                            <label class="form-checkbox">
                                              <input
                                                type="checkbox"
                                                v-model="selectAll"
                                                @click="select"
                                              />
                                              <i class="form-icon"></i>
                                            </label>
                                          </th>
                                          <th>{{ $t("id") }}</th>
                                          <th>{{ $t("name_en") }}</th>
                                        </tr>
                                      </thead>
                                    </template>
                                    <template v-slot:body="{ items }">
                                      <tbody style="white-space: nowrap">
                                        <tr
                                          v-for="(stu, index) in items"
                                          v-bind:key="index"
                                        >
                                          <td>
                                            <label class="form-checkbox">
                                              <input
                                                type="checkbox"
                                                :value="stu.id"
                                                v-model="selected"
                                              />
                                              <i class="form-icon"></i>
                                            </label>
                                          </td>
                                          <td>{{ stu.student_id }}</td>
                                          <td>{{ stu.name_en }}</td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-data-table>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          @click.prevent="onSaveClose()"
                          :disabled="!valid"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>
              <v-breadcrumbs :items="items" :href="items.href">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>

              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listSession"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("start_date") }}</th>
                              <th>{{ $t("end_date") }}</th>

                              <th>{{ $t("student") }}</th>

                              <th class="text-center">{{ $t("action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody
                            style="white-space: nowrap"
                            v-if="items.length > 0"
                          >
                            <tr
                              v-for="(sess, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ sess.name_en }}</td>
                              <td>{{ sess.name_kh }}</td>
                              <td>{{ sess.start_date }}</td>
                              <td>{{ sess.end_date }}</td>
                              <td>{{ sess.student_ids.length }}</td>
                              <td class="text-center">
                                <v-btn
                                  @click="
                                    $router.push({
                                      name: 'ManageSubject',
                                      params: {
                                        id: sess.id,
                                        name_en: sess.name_en,
                                        gen_id: sess.major_generation_id,
                                      },
                                    })
                                  "
                                  depressed
                                  small
                                  class="mx-2"
                                  color="teal"
                                  dark
                                >
                                  VIEW
                                </v-btn>

                                <v-btn
                                  @click="onEditItem(sess)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="primary"
                                >
                                  <v-icon size="15" dark>fa fa-pen</v-icon>
                                </v-btn>
                                <v-btn
                                  @click="deletesession(sess)"
                                  class="mx-2"
                                  fab
                                  color="error"
                                  small
                                >
                                  <v-icon size="15" dark>fa fa-trash</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                :colspan="headers.length"
                                style="text-align: center"
                              >
                                <v-alert dense outlined type="error">
                                  No data available in table
                                </v-alert>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import store from "@/store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  getSessionByMajor,
  getStudentByMajorId,
  getAcademicYear,
  createSession,
  updateSession,
  deleteSession,
} from "@schoolbase/web-client-lib";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Major ID", value: "major_id" },
        { text: "Major Generation ID", value: "major_generation_id" },
        { text: "Academic ID", value: "academic_id" },
      ],
      headers1: [
        { text: "Student ID", value: "student_id" },
        { text: "English Name", value: "name_en" },
      ],
      rules: {
        required: (v) => !!v || "This field is required",
      },
      token: store.getters.getToken,
      selected: [],
      selectAll: false,
      isLoading: false,
      myLoading: false,
      form: false,
      valid: true,
      listAcademicYear: [],
      majorGenId: "",
      value: "",
      start_date: "",
      student_ids: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      end_date: "",
      academic_id: "",
      major_id: "",
      major_generation_id: "",
      sessionId: "",
      items: [
        {
          text: "Generation",
          disabled: false,
          href: "javascript:history.go(-1)",
        },
        {
          text: "Session",
          disabled: true,
        },
      ],
      listStudent: [],
      listSession: [],
      dialog: false,
      dialog2: false,
      dialog1: false,
      menuJDate1: false,
      menuJDate2: false,
      name_en: "",
      name_kh: "",
    };
  },
  components: {
    Loading,
  },
  computed: {},
  methods: {
    onEditItem(item) {
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.academic_id = item.academic_id;
      this.start_date = item.start_date;
      this.end_date = item.end_date;
      this.sessionId = item.id;
      this.selected = item.student_ids;
      this.major_id = item.major_id;
      this.major_generation_id = item.major_generation_id;
      this.dialog = true;
    },
    // Clear Data After User click cancel or dialoge is false
    clear() {
      this.name_en = "";
      this.name_kh = "";
      this.id = "";
      this.sessionId = "";
      this.start_date = "";
      this.end_date = "";
      this.selected = [];
      this.selectAll = "";
      this.student_ids = "";
      this.major_id = "";
      this.singleSelect = "";
      this.major_generation_id = "";
      this.academic_id = "";
    },

    //Create User Function

    async onSaveClose() {
      let data = {
        name_kh: this.name_kh,
        name_en: this.name_en,
        major_generation_id: this.$route.params.id,
        major_id: this.$route.params.major_id,
        start_date: this.start_date,
        academic_id: this.academic_id,
        end_date: this.end_date,
        student_ids: this.selected,
      };
      if (this.sessionId) {
        if (this.sessionId !== "") {
          try {
            await updateSession(this.sessionId, data);
            this.loadSessions();
          } catch (e) {
            this.snackbar = {
              message: e,
              color: "error",
              show: true,
            };
          }
        }
      } else {
        try {
          await createSession(data);
          this.loadSessions();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.clear();
      this.dialog = false;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.listStudent) {
          this.selected.push(this.listStudent[i].id);
        }
      }
    },
    async loadAcademicYear() {
      try {
        let loggedUser = store.getters.getLoggedUser;
        let academicAuth = loggedUser.payload.token;
        const APIResponse = await getAcademicYear(academicAuth, this.token);
        this.listAcademicYear = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },

    async loadSessions() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSessionByMajor(
          this.$route.params.major_id,
          this.$route.params.id,
          this.token
        );

        this.listSession = APIResponse.payload.sort(function (a, b) {
          var nameA = a.name_en;
          var nameB = b.name_en;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async loadStudent() {
      try {
        const response = await getStudentByMajorId(
          this.$route.params.major_id,
          this.$route.params.id,
          this.token
        );
        this.listStudent = response.payload;
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }
      } catch (e) {
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async deletesession(sess) {
      const index = this.listSession.indexOf(sess);
      this.deletItems = sess;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listSession.splice(index, 1);
        await this.deleteSession();
      }
    },
    async deleteSession() {
      try {
        await deleteSession(this.deletItems.id);
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.loadAcademicYear();
    this.loadSessions();
    this.loadStudent();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}
.btn_create_new {
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
